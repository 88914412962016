export default [
	{
		path: 'advisory-manage',
		component: () => import('pages/advisory-manage/index'),
		children: [
			{
				path: '',
				component: () => import('pages/advisory-manage/consultations'),
			},
			{
				path: 'questions',
				component: () => import('pages/advisory-manage/questions'),
			},
		],
	},
	{
		path: 'promotion',
		name: 'promotionRecords',
		component: () => import('pages/promotion-manage/records'),
	},
	{
		path: 'promotion-manage',
		name: 'promotionManage',
		component: () => import('pages/promotion-manage/index'),
	},
	{
		path: 'blood-sugar-manage',
		name: 'bloodSugarManage',
		component: () => import('pages/blood-sugar-manage/index'),
	},
	{
		path: 'blood-sugar-manage/:uid/:snapshotId',
		name: 'bloodSugarDetail',
		component: () => import('pages/blood-sugar-manage/detail'),
	},
	{
		path: 'blood-sugar-manage/report/:uid/:snapshotId',
		name: 'bloodSugarReport',
		component: () => import('pages/blood-sugar-manage/report'),
	},
];
