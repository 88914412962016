export default [
	{
		path: 'statistics',
		component: () => import('pages/statistics/statistics-base'),
		children: [
			{ path: '', redirect: 'monitor' },
			{
				path: 'monitor',
				component: () => import('pages/statistics/statistics-monitor/statistics-monitor-base'),
				children: [
					{ path: '', redirect: 'body' },
					{
						path: 'blood',
						component: () => import('pages/statistics/statistics-monitor/statistics-monitor-blood'),
					},
					{
						path: 'sugar',
						component: () => import('pages/statistics/statistics-monitor/statistics-monitor-sugar'),
					},
					{
						path: 'uric',
						component: () => import('pages/statistics/statistics-monitor/statistics-monitor-uric'),
					},
					{
						path: 'cho',
						component: () => import('pages/statistics/statistics-monitor/statistics-monitor-cho'),
					},
					{
						path: 'height',
						component: () =>
							import('pages/statistics/statistics-monitor/statistics-monitor-height'),
					},
					{
						path: 'body',
						component: () => import('pages/statistics/statistics-monitor/statistics-monitor-body'),
					},
					{
						path: 'grip',
						component: () => import('pages/statistics/statistics-monitor/statistics-monitor-grip'),
					},
					{
						path: 'capacity',
						component: () =>
							import('pages/statistics/statistics-monitor/statistics-monitor-capacity'),
					},
					{
						path: 'balanced',
						component: () =>
							import('pages/statistics/statistics-monitor/statistics-monitor-balanced'),
					},
				],
			},
			{ path: 'sport', component: () => import('pages/statistics/statistics-sport') },
			{ path: 'train', component: () => import('pages/statistics/statistics-train') },
			{ path: 'drug', component: () => import('pages/statistics/statistics-drug') },
			{ path: 'food', component: () => import('pages/statistics/statistics-food') },
			{ path: 'plan', component: () => import('pages/statistics/statistics-plan') },
			{ path: 'service', component: () => import('pages/statistics/statistics-service') },
			{ path: 'quality', component: () => import('pages/statistics/statistics-quality') },
			{ path: 'boost', component: () => import('pages/statistics/statistics-boost') },
			{ path: 'doctor', component: () => import('pages/statistics/statistics-doctor') },
			{ path: 'warning', component: () => import('pages/statistics/statistics-warning') },
			{ path: 'problem', component: () => import('pages/statistics/statistics-problem') },
			{ path: 'ranking', component: () => import('pages/statistics/statistics-ranking') },
			{ path: 'sick', component: () => import('pages/statistics/statistics-sick') },
		],
	},
];
