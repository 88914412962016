export default [
	{ path: 'home', component: () => import('pages/home/home') },
	{
		path: 'home/report',
		component: () => import('pages/home/report/index'),
		children: [
			{ path: 'all', name: 'HomeReport', component: () => import('pages/home/report/all-reports') },
			{
				path: 'customer',
				name: 'HomeReportCustomer',
				component: () => import('pages/home/report/customer-reports'),
			},
		],
	},
	{
		path: 'home/class',
		component: () => import('pages/home/class/index'),
		name: 'HomeClass',
	},
	{
		path: 'home/reconstru',
		component: () => import('pages/home/reconstru/index'),
		name: 'HomeReconstru',
	},
	{
		path: 'home/monitor',
		component: () => import('pages/home/monitor/index'),
		name: 'HomeMonitor',
	},
];
