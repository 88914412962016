export default [
	{
		path: 'report',
		component: () => import('pages/report/report-base'),
		redirect: '/base/report/physiology',
		children: [
			{
				name: 'reportPhysiology',
				path: 'physiology',
				component: () => import('pages/report/physiology/base'),
			},
			{
				name: 'reportCustomer',
				path: 'customer',
				component: () => import('pages/report/customer/base'),
			},
			{
				name: 'reportHeart',
				path: 'heart',
				component: () => import('pages/report/heart/base'),
			},
			{
				name: 'reportTrain',
				path: 'train',
				component: () => import('pages/report/train/base'),
			},
			{
				name: 'reportPatient',
				path: 'patient',
				component: () => import('pages/report/patient/base'),
			},
			{
				name: 'reportQuantify',
				path: 'quantify',
				component: () => import('pages/report/quantify/base'),
			},
			{
				name: 'reportBloodPressure',
				path: 'pressure',
				component: () => import('pages/report/blood-pressure/base'),
			},
			{
				name: 'reportBloodSugar',
				path: 'sugar',
				component: () => import('pages/report/blood-sugar/base'),
			},
			{
				name: 'reportSun',
				path: 'sun',
				component: () => import('pages/report/sun/sun_base'),
			},
		],
	},
];
