export default [
	{ path: 'plan', component: () => import('pages/plan/plan-index') },
	{
		path: 'plan/waitmake/:member_id/:plan_id',
		name: 'waitMake',
		component: () => import('pages/plan/plan-waitmake'),
	},
	{
		path: 'plan/waitcheck/:member_id/:plan_id',
		name: 'waitCheck',
		component: () => import('pages/plan/plan-waitcheck'),
	},
	{
		path: 'plan/planDetail/:member_id/:plan_id',
		name: 'planDetail',
		component: () => import('pages/plan/plan-detail'),
	},
	{
		path: 'plan/adjust/:member_id/:plan_id',
		name: 'adjust',
		component: () => import('pages/plan/plan-adjust'),
	},
	{
		path: 'plan/remake/:member_id/:plan_id',
		name: 'remake',
		component: () => import('pages/plan/plan-remake'),
	},
	{
		path: 'plan/new',
		name: 'newPlan',
		component: () => import('pages/new-plan/list'),
	},
	{
		path: 'plan/new/generate/:id',
		name: 'generateReport',
		component: () => import('pages/new-plan/index'),
	},
];
