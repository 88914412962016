import { Message } from 'view-design';
import { Loading } from 'element-ui';
import axios from 'axios';
import qs from 'qs';
import Router from 'vue-router';
import routers from '@/router/index';

const router = new Router({
	routers,
	linkActiveClass: 'active',
});

const ERR_OK = 0;
const NOT_LOGIN = 10011;

let loading;
const xhr = axios.create({ baseURL: '/api', timeout: 60000 });

xhr.interceptors.request.use((config) => {
	loading = Loading.service({ fullscreen: true });
	if (config.params) {
		const params = config.params;
		if (params.export) {
			config.responseType = 'blob';
		}
		if (config.method === 'post') {
			config.data = params.isFile ? params : qs.stringify(params);
		}
	}
	return config;
});

xhr.interceptors.response.use(
	(response) => {
		loading.close();
		const { data, headers } = response;
		return new Promise((resolve, reject) => {
			if (headers['content-disposition']) {
				const blob = new Blob([data], { type: headers['content-type'] });
				return resolve(blob);
			}
			if (data.c === ERR_OK) {
				resolve(data.data || data.d);
			} else if (data.c === NOT_LOGIN) {
				localStorage.removeItem('loginInfo');
				localStorage.removeItem('userInfo');
				localStorage.removeItem('userPower');
				Message.warning(data.m);
				router.push({ path: '/login' });
			} else {
				Message.warning(data.m);
				reject(data.m);
			}
		});
	},
	() => {
		loading.close();
		Message.warning('请求 API 失败,请检查网络是否正常');
	},
);

export default xhr;
