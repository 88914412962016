export default [
	{
		path: 'card',
		component: () => import('pages/card-manage/index'),
		redirect: '/base/card/overview',
		children: [
			{
				path: 'overview',
				name: 'cardOverview',
				component: () => import('pages/card-manage/overview'),
			},
			{
				path: 'application',
				name: 'cardApplication',
				component: () => import('pages/card-manage/application'),
			},
			{
				path: 'service',
				name: 'cardService',
				component: () => import('pages/card-manage/service'),
			},
			{
				path: 'dispatch',
				name: 'cardDispatch',
				component: () => import('pages/card-manage/dispatch'),
			},
			{
				path: 'dispatch/management',
				name: 'cardManagement',
				component: () => import('pages/card-manage/management'),
			},
		],
	},
];
