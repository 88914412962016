// 不同功能模块的路由应代码分离
import homeRoutes from './map/home';
import memberRoutes from './map/member';
import planRoutes from './map/plan';
import boostRoutes from './map/boost';
import doctorRoutes from './map/doctor';
import qualityRoutes from './map/quality';
import problemRoutes from './map/problem';
import warningRoutes from './map/warning';
import statisticsRoutes from './map/statistics';
import chartRoutes from './map/chart';
import userRoutes from './map/user';
import reportRoutes from './map/report';
import serviceRoutes from './map/service';
import questionnaireRoutes from './map/questionnaire';
import cardRoutes from './map/card';
import copyrightRoutes from './map/copyright';
import familyRoutes from './map/family';

// 判断自动登录
const autoLogin = (to, from, next) => {
	if (to.matched.some((record) => record.meta.auto)) {
		if (!localStorage.getItem('autoLogin')) {
			next();
		} else {
			next({
				path: '/base/home',
			});
		}
	} else {
		next();
	}
};
export default [
	{ path: '/', redirect: '/login' },
	{
		path: '/login',
		name: 'login',
		meta: { auto: true },
		component: () => import('pages/login'),
		beforeEnter: autoLogin,
	},
	{
		path: '/base',
		name: 'base',
		component: () => import('pages/base'),
		meta: { requiresAuth: true },
		children: [
			...homeRoutes,
			...memberRoutes,
			...planRoutes,
			...boostRoutes,
			...doctorRoutes,
			...qualityRoutes,
			...problemRoutes,
			...warningRoutes,
			...statisticsRoutes,
			...chartRoutes,
			...userRoutes,
			...reportRoutes,
			...serviceRoutes,
			...questionnaireRoutes,
			...cardRoutes,
			...copyrightRoutes,
			...familyRoutes,
			{ path: 'life', name: 'LifeManagement', component: () => import('pages/life') },
			{ path: 'punch', name: 'PunchManagement', component: () => import('pages/punch-in') },
		],
	},
	{
		path: '/promotion-manage/:memberId/:id',
		name: 'promotionDetail',
		component: () => import('pages/promotion-manage/screen'),
	},
	{ path: '/404', component: () => import('pages/404') },
	{ path: '*', redirect: '/404' }, // 其他页面，强制跳转404
];
