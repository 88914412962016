export default [
	{
		path: 'chart',
		component: () => import('pages/chart/chart-base'),
		children: [
			{ path: '', redirect: 'basic' },
			{ path: 'basic', component: () => import('pages/chart/chart-basic') },
			{ path: 'sport', component: () => import('pages/chart/chart-sport') },
			{ path: 'monitor', component: () => import('pages/chart/chart-monitor') },
			{ path: 'train', component: () => import('pages/chart/chart-train') },
			{ path: 'plan', component: () => import('pages/chart/chart-plan') },
			{ path: 'service', component: () => import('pages/chart/chart-service') },
			{ path: 'boost', component: () => import('pages/chart/chart-boost') },
			{ path: 'warning', component: () => import('pages/chart/chart-warning') },
			{ path: 'problem', component: () => import('pages/chart/chart-problem') },
		],
	},
];
