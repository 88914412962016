export default [
	{ path: 'quality', component: () => import('pages/quality/quality-index') },
	{
		path: 'quality/list/:member_id',
		name: 'qualityList',
		component: () => import('pages/quality/quality-list'),
	},
	{
		path: 'quality/start/:member_id',
		name: 'qualityStart',
		component: () => import('pages/quality/quality-start'),
	},
	{
		path: 'quality/detail/:boost_id',
		name: 'qualityDetail',
		component: () => import('pages/quality/quality-detail'),
	},
];
