export default [
	{ path: 'problem', component: () => import('pages/problem/problem-index') },
	{
		path: 'problem/detail/:problem_id',
		name: 'problemDetail',
		component: () => import('pages/problem/problem-detail'),
	},
	{
		path: 'problem/edit/:member_id/:problem_id',
		name: 'problemEdit',
		component: () => import('pages/problem/problem-edit'),
	},
];
