export default [
	{ path: 'boost', component: () => import('pages/boost/boost-index') },
	{
		path: 'boost/list/:member_id',
		name: 'boostList',
		component: () => import('pages/boost/boost-list'),
	},
	{
		path: 'boost/start/:member_id',
		name: 'boostStart',
		component: () => import('pages/boost/boost-start'),
	},
	{
		path: 'boost/detail/:boost_id',
		name: 'boostDetail',
		component: () => import('pages/boost/boost-detail'),
	},
];
