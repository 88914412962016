export default [
	{ path: 'doctor', component: () => import('pages/doctor/doctor-index') },
	{
		path: 'doctor/list/:member_id',
		name: 'doctorList',
		component: () => import('pages/doctor/doctor-list'),
	},
	{
		path: 'doctor/start/:member_id',
		name: 'doctorStart',
		component: () => import('pages/doctor/doctor-start'),
	},
	{
		path: 'doctor/detail/:boost_id',
		name: 'doctorDetail',
		component: () => import('pages/doctor/doctor-detail'),
	},
];
