export default [
	{ path: 'member', component: () => import('pages/member/member-index/member') },
	{
		path: 'member/newMember',
		name: 'newMember',
		component: () => import('pages/member/newMember'),
	},
	{
		path: 'member/detail/:member_id',
		component: () => import('pages/member/member-detail/member-detail-base'),
		children: [
			{ path: '', redirect: '0' },
			{
				path: '0',
				name: 'personIndex',
				component: () => import('pages/member/member-detail/PersonalDataList/person-index'),
			},
			{
				path: '1',
				name: 'pushProject',
				component: () => import('pages/member/member-detail/push-project'),
			},
			{
				path: '2/:id',
				name: 'memberInfo',
				component: () => import('pages/member/member-detail/member-info'),
			},
			{
				path: '3',
				component: () => import('pages/member/member-detail/illnessData/illness-info'),
				children: [
					{ path: '', redirect: 'illness' },
					{
						path: 'illness',
						name: 'illness',
						component: () => import('pages/member/member-detail/illnessData/illness-table'),
					},
					{
						path: 'hereditary',
						name: 'hereditary',
						component: () => import('pages/member/member-detail/illnessData/illness-heredity'),
					},
					{
						path: 'trauma',
						name: 'trauma',
						component: () => import('pages/member/member-detail/illnessData/trauma'),
					},
					{
						path: 'main',
						name: 'main',
						component: () => import('pages/member/member-detail/illnessData/main'),
					},
				],
			},
			{ path: '4', name: 'Drug', component: () => import('pages/member/member-detail/Drug') },
			{
				path: '4/:monitorId',
				name: 'Drug2',
				component: () => import('pages/member/member-detail/Drug'),
			},
			{
				path: '4/0',
				name: 'addDrug',
				component: () => import('pages/member/member-detail/Drug-Add'),
			},
			{
				path: '4/1/:drug_id',
				name: 'detailDrug',
				component: () => import('pages/member/member-detail/Drug-Detail'),
			},
			{
				path: '5',
				component: () => import('pages/member/member-detail/monitorData/monitor-base'),
				children: [
					{ path: '', redirect: 'Body' },
					{
						path: 'Blood',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '0' },
							{
								path: '0',
								name: 'Blood',
								component: () => import('pages/member/member-detail/monitorData/Blood-index'),
							},
							{
								path: '1',
								name: 'BloodChange',
								component: () => import('pages/member/member-detail/monitorData/Blood-Add'),
							},
							{
								path: '2/:monitorId',
								name: 'BloodTable2',
								component: () => import('pages/member/member-detail/monitorData/Blood-Table'),
							},
							{
								path: '2',
								name: 'BloodTable',
								component: () => import('pages/member/member-detail/monitorData/Blood-Table'),
							},
							{
								path: '3',
								name: 'BloodChart',
								component: () => import('pages/member/member-detail/monitorData/Blood-Chart'),
							},
							{
								path: '4/:id',
								name: 'BloodEdit',
								component: () => import('pages/member/member-detail/monitorData/Blood-Edit'),
							},
						],
					},
					{
						path: 'BloodO2',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '45' },
							{
								path: '45',
								name: 'BloodO2',
								component: () => import('pages/member/member-detail/monitorData/BloodO2-index'),
							},
							{
								path: '46',
								name: 'BloodO2Change',
								component: () => import('pages/member/member-detail/monitorData/BloodO2-Add'),
							},
							{
								path: '47/:monitorId',
								name: 'BloodO2Table2',
								component: () => import('pages/member/member-detail/monitorData/BloodO2-Table'),
							},
							{
								path: '47',
								name: 'BloodO2Table',
								component: () => import('pages/member/member-detail/monitorData/BloodO2-Table'),
							},
							{
								path: '48',
								name: 'BloodO2Chart',
								component: () => import('pages/member/member-detail/monitorData/BloodO2-Chart'),
							},
							{
								path: '59/:id',
								name: 'BloodO2Edit',
								component: () => import('pages/member/member-detail/monitorData/BloodO2-Edit'),
							},
						],
					},
					{
						path: 'BloodSugar',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '5' },
							{
								path: '5',
								name: 'BloodSugar',
								component: () => import('pages/member/member-detail/monitorData/BloodSugar-index'),
							},
							{
								path: '6',
								name: 'BloodSugarTable',
								component: () => import('pages/member/member-detail/monitorData/BloodSugar-Table'),
							},
							{
								path: '6/:monitorId',
								name: 'BloodSugarTable2',
								component: () => import('pages/member/member-detail/monitorData/BloodSugar-Table'),
							},
							{
								path: '7',
								name: 'BloodSugarChart',
								component: () => import('pages/member/member-detail/monitorData/BloodSugar-Chart'),
							},
							{
								path: '9',
								name: 'BloodSugarChange',
								component: () => import('pages/member/member-detail/monitorData/BloodSugar-Add'),
							},
							{
								path: '8/:sugarId',
								name: 'BloodSugarEdit',
								component: () => import('pages/member/member-detail/monitorData/BloodSugar-Edit'),
							},
						],
					},
					{
						path: 'UricAcid',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '10' },
							{
								path: '10',
								name: 'UricAcid',
								component: () => import('pages/member/member-detail/monitorData/UricAcid-index'),
							},
							{
								path: '11',
								name: 'UricAcidChange',
								component: () => import('pages/member/member-detail/monitorData/UricAcid-Add'),
							},
							{
								path: '12',
								name: 'UricAcidTable',
								component: () => import('pages/member/member-detail/monitorData/UricAcid-Table'),
							},
							{
								path: '12/:monitorId',
								name: 'UricAcidTable2',
								component: () => import('pages/member/member-detail/monitorData/UricAcid-Table'),
							},
							{
								path: '13',
								name: 'UricAcidChart',
								component: () => import('pages/member/member-detail/monitorData/UricAcid-Chart'),
							},
							{
								path: '14/:uricId',
								name: 'UricAcidEdit',
								component: () => import('pages/member/member-detail/monitorData/UricAcid-Edit'),
							},
						],
					},
					{
						path: 'Cholesterin',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '15' },
							{
								path: '15',
								name: 'Cholesterin',
								component: () => import('pages/member/member-detail/monitorData/Cholesterin-index'),
							},
							{
								path: '16',
								name: 'CholesterinTable',
								component: () => import('pages/member/member-detail/monitorData/Cholesterin-Table'),
							},
							{
								path: '16/:monitorId',
								name: 'CholesterinTable2',
								component: () => import('pages/member/member-detail/monitorData/Cholesterin-Table'),
							},
							{
								path: '17',
								name: 'CholesterinChart',
								component: () => import('pages/member/member-detail/monitorData/Cholesterin-Chart'),
							},
							{
								path: '18',
								name: 'CholesterinAdd',
								component: () => import('pages/member/member-detail/monitorData/Cholesterin-Add'),
							},
							{
								path: '19/:cholId',
								name: 'CholesterinEdit',
								component: () => import('pages/member/member-detail/monitorData/Cholesterin-Edit'),
							},
						],
					},
					{
						path: 'Wastiline',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '20' },
							{
								path: '20',
								name: 'Wastiline',
								component: () => import('pages/member/member-detail/monitorData/Wastiline-index'),
							},
							{
								path: '21',
								name: 'WastilineTable',
								component: () => import('pages/member/member-detail/monitorData/Wastiline-Table'),
							},
							{
								path: '21/:monitorId',
								name: 'WastilineTable2',
								component: () => import('pages/member/member-detail/monitorData/Wastiline-Table'),
							},
							{
								path: '22',
								name: 'WastilineChart',
								component: () => import('pages/member/member-detail/monitorData/Wastiline-Chart'),
							},
							{
								path: '23',
								name: 'WastilineAdd',
								component: () => import('pages/member/member-detail/monitorData/Wastiline-Add'),
							},
							{
								path: '24/:heightId',
								name: 'WastilineEdit',
								component: () => import('pages/member/member-detail/monitorData/Wastiline-Edit'),
							},
						],
					},
					{
						path: 'Body',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '25' },
							{
								path: '25',
								name: 'Body',
								component: () => import('pages/member/member-detail/monitorData/Body-index'),
							},
							{
								path: '26',
								name: 'BodyTable',
								component: () => import('pages/member/member-detail/monitorData/Body-Table'),
							},
							{
								path: '26/:monitorId',
								name: 'BodyTable2',
								component: () => import('pages/member/member-detail/monitorData/Body-Table'),
							},
							{
								path: '27',
								name: 'BodyChart',
								component: () => import('pages/member/member-detail/monitorData/Body-Chart'),
							},
							{
								path: '28',
								name: 'BodyAdd',
								component: () => import('pages/member/member-detail/monitorData/Body-Add'),
							},
							{
								path: '29/:bodyId',
								name: 'BodyEdit',
								component: () => import('pages/member/member-detail/monitorData/Body-Edit'),
							},
						],
					},
					{
						path: 'Grip',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '30' },
							{
								path: '30',
								name: 'Grip',
								component: () => import('pages/member/member-detail/monitorData/Grip-index'),
							},
							{
								path: '31',
								name: 'GripTable',
								component: () => import('pages/member/member-detail/monitorData/Grip-Table'),
							},
							{
								path: '31/:monitorId',
								name: 'GripTable2',
								component: () => import('pages/member/member-detail/monitorData/Grip-Table'),
							},
							{
								path: '32',
								name: 'GripChart',
								component: () => import('pages/member/member-detail/monitorData/Grip-Chart'),
							},
							{
								path: '33',
								name: 'GripAdd',
								component: () => import('pages/member/member-detail/monitorData/Grip-Add'),
							},
							{
								path: '34/:gripId',
								name: 'GripEdit',
								component: () => import('pages/member/member-detail/monitorData/Grip-Edit'),
							},
						],
					},
					{
						path: 'Capacity',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '35' },
							{
								path: '35',
								name: 'Capacity',
								component: () => import('pages/member/member-detail/monitorData/Capacity-index'),
							},
							{
								path: '36',
								name: 'CapacityTable',
								component: () => import('pages/member/member-detail/monitorData/Capacity-Table'),
							},
							{
								path: '36/:monitorId',
								name: 'CapacityTable2',
								component: () => import('pages/member/member-detail/monitorData/Capacity-Table'),
							},
							{
								path: '37',
								name: 'CapacityChart',
								component: () => import('pages/member/member-detail/monitorData/Capacity-Chart'),
							},
							{
								path: '38',
								name: 'CapacityAdd',
								component: () => import('pages/member/member-detail/monitorData/Capacity-Add'),
							},
							{
								path: '39/:capId',
								name: 'CapacityEdit',
								component: () => import('pages/member/member-detail/monitorData/Capacity-Edit'),
							},
						],
					},
					{
						path: 'Balanced',
						component: () => import('pages/member/member-detail/monitorData/Blood-base'),
						children: [
							{ path: '', redirect: '40' },
							{
								path: '40',
								name: 'Balanced',
								component: () => import('pages/member/member-detail/monitorData/Balanced-index'),
							},
							{
								path: '41',
								name: 'BalancedTable',
								component: () => import('pages/member/member-detail/monitorData/Balanced-Table'),
							},
							{
								path: '42',
								name: 'BalancedChart',
								component: () => import('pages/member/member-detail/monitorData/Balanced-Chart'),
							},
							{
								path: '43',
								name: 'BalancedAdd',
								component: () => import('pages/member/member-detail/monitorData/Balanced-Add'),
							},
							{
								path: '44/:balanceId',
								name: 'BalancedEdit',
								component: () => import('pages/member/member-detail/monitorData/Balanced-Edit'),
							},
						],
					},
				],
			},
			{
				path: '6',
				name: 'CheckPrint',
				component: () => import('pages/member/member-detail/CheckPrint-base'),
			},
			{
				path: '6/0',
				name: 'CheckPrintAdd',
				component: () => import('pages/member/member-detail/CheckPrint-Add'),
			},
			{
				path: '6/1/:pic_id',
				name: 'CheckPrintDetail',
				component: () => import('pages/member/member-detail/CheckPrint-Detail'),
			},
			{
				path: 'report',
				name: 'memberReport',
				component: () => import('pages/member/member-detail/report-base'),
			},
			{
				path: 'report/add',
				name: 'memberReportAdd',
				component: () => import('pages/member/member-detail/report-add'),
			},
			{
				path: 'report/detail/:id',
				name: 'memberReportDetail',
				component: () => import('pages/member/member-detail/report-detail'),
			},
			{
				path: 'punch-in',
				name: 'PunchIn',
				component: () => import('pages/member/member-detail/punch-in'),
			},
			{
				path: '7',
				component: () => import('pages/member/member-detail/monitorData/Blood-base'),
				children: [
					{ path: '', redirect: 'table' },
					{
						path: '7/0/:type_id',
						name: 'FoodRecordAdd',
						component: () => import('pages/member/member-detail/FoodRecord-Add'),
					},
					{
						path: '7/1/:food_id',
						name: 'FoodRecordList',
						component: () => import('pages/member/member-detail/FoodRecord-List'),
					},
					{
						path: 'table',
						name: 'FoodTable',
						component: () => import('pages/member/member-detail/FoodRecord-Table'),
					},
					{
						path: 'table/:monitorId',
						name: 'FoodTable2',
						component: () => import('pages/member/member-detail/FoodRecord-Table'),
					},
					{
						path: ':page/chart',
						name: 'FoodChart',
						component: () => import('pages/member/member-detail/FoodRecord-Chart'),
					},
				],
			},
			{
				path: '8',
				name: 'PlanRecord',
				component: () => import('pages/member/member-detail/PlanRecord-base'),
			},
			{
				path: 'Sports',
				component: () => import('pages/member/member-detail/monitorData/Blood-base'),
				children: [
					{ path: '', redirect: '9' },
					{
						path: '9',
						name: 'Sports',
						component: () => import('pages/member/member-detail/Sports-index'),
					},
					{
						path: '10',
						name: 'SportsTable',
						component: () => import('pages/member/member-detail/Sports-Table'),
					},
					{
						path: '10/:monitorId',
						name: 'SportsTable2',
						component: () => import('pages/member/member-detail/Sports-Table'),
					},
					{
						path: '11',
						name: 'SportsChart',
						component: () => import('pages/member/member-detail/Sports-Chart'),
					},
					{
						path: '12',
						name: 'SportsAdd',
						component: () => import('pages/member/member-detail/Sports-Add'),
					},
					{
						path: '13/:sport_id',
						name: 'SportsEdit',
						component: () => import('pages/member/member-detail/Sports-Edit'),
					},
				],
			},
			{
				path: 'class',
				component: () => import('pages/home/class/member-class'),
				name: 'memberClass',
			},
			{
				path: 'reconstru',
				component: () => import('pages/home/reconstru/member-reconstru'),
				name: 'memberReconstru',
			},
			{
				path: 'Train',
				component: () => import('pages/member/member-detail/monitorData/Blood-base'),
				children: [
					{ path: '', redirect: '14' },
					{
						path: '14',
						name: 'Train',
						component: () => import('pages/member/member-detail/new-train/index'),
					},
					{
						path: '15',
						name: 'TrainTable',
						component: () => import('pages/member/member-detail/new-train/table'),
					},
					{
						path: '15/:monitorId',
						name: 'TrainTable2',
						component: () => import('pages/member/member-detail/new-train/table'),
					},
					{
						path: '16/:monitorId',
						name: 'TrainChart',
						component: () => import('pages/member/member-detail/new-train/chart'),
					},
					{
						path: '19',
						name: 'TrainReport',
						component: () => import('pages/member/member-detail/new-train/report'),
					},
					{
						path: '19/:train_id',
						name: 'TrainReportDetail',
						component: () => import('pages/member/member-detail/new-train/report-detail'),
					},
					{
						path: '17',
						name: 'TrainAdd',
						component: () => import('pages/member/member-detail/Train-Add'),
					},
					{
						path: '18/:tran_id',
						name: 'TrainEdit',
						component: () => import('pages/member/member-detail/Train-Edit'),
					},
				],
			},
			{
				path: 'Services',
				component: () => import('pages/member/member-detail/monitorData/Blood-base'),
				children: [
					{ path: '', redirect: '19' },
					{
						path: '19',
						name: 'ServicesTable',
						component: () => import('pages/member/member-detail/Services-index'),
					},
					{
						path: '20',
						name: 'ServicesAdd',
						component: () => import('pages/member/member-detail/Services-Add'),
					},
					{
						path: '21/:services_id',
						name: 'ServicesLook',
						component: () => import('pages/member/member-detail/Services-Look'),
					},
				],
			},
			{
				path: 'Linghuo',
				component: () => import('pages/member/member-detail/monitorData/Blood-base'),
				children: [
					{ path: '', redirect: '63' },
					{
						path: '63',
						name: 'LinghuoTable',
						component: () => import('pages/member/member-detail/Linghuo-index'),
					},
					{
						path: '64',
						name: 'LinghuoAdd',
						component: () => import('pages/member/member-detail/Linghuo-Add'),
					},
					{
						path: '65/:services_id',
						name: 'LinghuoLook',
						component: () => import('pages/member/member-detail/Linghuo-Look'),
					},
				],
			},
			{
				path: 'Problem',
				component: () => import('pages/member/member-detail/monitorData/Blood-base'),
				children: [
					{ path: '', redirect: '22' },
					{
						path: '22',
						name: 'ServicesProblem',
						component: () => import('pages/member/member-detail/Services-Problem'),
					},
					{
						path: '23/:problem_id',
						name: 'ServicesProblemDetail',
						component: () => import('pages/member/member-detail/Services-Problem-Detail'),
					},
					{
						path: '24',
						name: 'ServicesProblemAdd',
						component: () => import('pages/member/member-detail/Services-Problem-Add'),
					},
				],
			},
			{
				path: 'watch',
				component: () => import('pages/member/member-detail/watchData/watch-base'),
				children: [
					{ path: '', redirect: '66' },
					{
						path: '66',
						name: 'WatchLine',
						component: () => import('pages/member/member-detail/watchData/watch-line'),
					},
					{
						path: '60',
						name: 'WatchSleep',
						component: () => import('pages/member/member-detail/watchData/watch-sleep'),
					},
					{
						path: '61',
						name: 'WatchHeart',
						component: () => import('pages/member/member-detail/watchData/watch-heart'),
					},
					{
						path: '62',
						name: 'WatchOxygen',
						component: () => import('pages/member/member-detail/watchData/watch-oxygen'),
					},
					{
						path: '63',
						name: 'WatchPressure',
						component: () => import('pages/member/member-detail/watchData/watch-pressure'),
					},
				],
			},
			{
				path: 'card',
				name: 'card',
				redirect: { name: 'cardServices' },
				component: () => import('pages/member/member-detail/buyCard/index'),
				children: [
					{
						path: 'services',
						name: 'cardServices',
						component: () => import('pages/member/member-detail/buyCard/card-service'),
					},
					{
						path: 'records',
						name: 'cardRecords',
						component: () => import('pages/member/member-detail/buyCard/card-table'),
					},
				],
			},
			{
				path: 'new-disease',
				name: 'newDisease',
				component: () => import('pages/member/member-detail/new-disease'),
			},
			{
				path: 'new-behavior',
				name: 'newBehavior',
				component: () => import('pages/member/member-detail/new-behavior'),
			},
			{
				path: 'new-monitor',
				name: 'newMonitor',
				component: () => import('pages/member/member-detail/new-monitor'),
			},
			{
				path: 'new-question',
				name: 'newQuestion',
				component: () => import('pages/member/member-detail/PersonalDataList/service-record'),
			},
			{
				path: 'ecg',
				name: 'ECGList',
				component: () => import('pages/member/member-detail/ECG/index'),
			},
			{
				path: 'ecg/behavior',
				name: 'ECGBehavior',
				component: () => import('pages/member/member-detail/ECG/behavior-record'),
			},
			{
				path: 'ecg/behavior/:record_id',
				name: 'ECGBebaviorPreview',
				component: () => import('pages/member/member-detail/ECG/behavior-preview'),
			},
			{
				path: 'ecg/physiology',
				name: 'ECGPhysiology',
				component: () => import('pages/member/member-detail/ECG/physiology-data'),
			},
			{
				path: 'ecg/physiology/:record_id',
				name: 'ECGPhysiologyPreview',
				component: () => import('pages/member/member-detail/ECG/physiology-preview'),
			},
			{
				path: 'ecg/summary',
				name: 'ECGSummary',
				component: () => import('pages/member/member-detail/ECG/summary'),
			},
			{
				path: 'ecg/summary/:record_id',
				name: 'ECGSummaryPreview',
				component: () => import('pages/member/member-detail/ECG/summary-preview'),
			},
			{
				path: 'ecg/summary/:record_id/evaluate',
				name: 'ECGEvaluate',
				component: () => import('pages/member/member-detail/ECG/evaluate/index'),
			},
		],
	},
];
